import React from 'react';
import { bool, func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { useRouteConfiguration } from '../../../../context/routeConfigurationContext';

import Field, { hasDataInFields } from '../../Field';
import { createResourceLocatorString } from '../../../../util/routes';
import SearchForm from './SearchForm/SearchForm';
import BlackBeauty from '../../../../assets/black_beauty.png';
import KrullMag from '../../../../assets/krull.png';
import RespectMag from '../../../../assets/respect.png';
import Sheen from '../../../../assets/sheen.png';
import Vogue from '../../../../assets/vogue.png';
import Popsugar from '../../../../assets/popsugar.png';
import Schick from '../../../../assets/schick.png';

import SectionContainer from '../SectionContainer';
import css from './SectionHero.module.css';

const brandImages = [
  { src: Vogue, alt: 'Vogue' },
  { src: KrullMag, alt: 'Krull Mag', display: 'desktop-only' },
  { src: Popsugar, alt: 'Popsugar', display: 'desktop-only' },
  { src: Sheen, alt: 'Sheen', display: 'desktop-only' },
  { src: Schick, alt: 'Schick', display: 'desktop-only' },
  { src: BlackBeauty, alt: 'Black Beauty' },
  { src: RespectMag, alt: 'Respect Mag' },
];

// Section component for a website's hero section
const SectionHero = props => {
  const {
    sectionId,
    className,
    rootClassName,
    defaultClasses,
    title,
    description,
    appearance,
    callToAction,
    options,
    pageType,
  } = props;

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const history = useHistory();

  const hasHeaderFields = hasDataInFields([title, description, callToAction], fieldOptions);
  const routeConfiguration = useRouteConfiguration();

  const handleSearchSubmit = values => {
    const { keywords, location } = values;
    const { search, selectedPlace } = location || {};
    const { origin, bounds } = selectedPlace || {};

    const searchParams = {};
    if (keywords) {
      searchParams.category = keywords;
    }
    if (search) {
      searchParams.address = search;
    }
    if (origin) {
      searchParams.origin = origin;
    }
    if (bounds) {
      searchParams.bounds = bounds;
    }
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  return (
    <>
      <SectionContainer
        id={sectionId}
        className={className}
        rootClassName={classNames(rootClassName || css.root)}
        appearance={appearance}
        options={fieldOptions}
        pageType={pageType}
      >
        {hasHeaderFields ? (
          <header className={defaultClasses.sectionDetails}>
            <Field data={title} className={defaultClasses.title} options={fieldOptions} />
            <Field
              data={description}
              className={defaultClasses.description}
              options={fieldOptions}
            />

            <SearchForm className={css.searchForm} onSubmit={handleSearchSubmit} />

            <Field
              data={callToAction}
              className={`${defaultClasses.ctaButton} ${css.mobileVisible}`}
              options={fieldOptions}
            />
          </header>
        ) : null}
      </SectionContainer>

      <div className={css.imageContainer}>
        {brandImages.map((image, index) => (
          <div
            key={index}
            className={classNames(css.imageWrapper, { [css.desktopOnly]: image.display })}
          >
            <img src={image.src} alt={image.alt} className={css.image} />
          </div>
        ))}
      </div>
    </>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionHero.defaultProps = {
  className: null,
  rootClassName: null,
  defaultClasses: null,
  textClassName: null,
  title: null,
  description: null,
  appearance: null,
  callToAction: null,
  isInsideContainer: false,
  options: null,
};

SectionHero.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  defaultClasses: shape({
    sectionDetails: string,
    title: string,
    description: string,
    ctaButton: string,
  }),
  title: object,
  description: object,
  appearance: object,
  callToAction: object,
  isInsideContainer: bool,
  options: propTypeOption,
};

export default SectionHero;
